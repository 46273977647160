import PropTypes from 'prop-types';
import { Input, Select } from 'antd';

import FormField from '../form-field/form-field';

import { getNestedObjectFieldValue } from '../../../utils/helpers/shared';

const { Option } = Select;

const FormFieldSelector = ({
  formik, form, data,
}) => data.map((item) => {
  if (item.isHidden) {
    return null;
  }

  if (item.format) {
    return (
      <FormField
        formik={formik}
        {...item}
        onChange={item.onChange ? item.onChange : (value) => {
          formik.setFieldValue(item.name, value);
        }}
        key={item.name}
      />
    );
  }

  if (item.mask) {
    const { formatter, ...otherProps } = item;

    return (
      <FormField
        formik={formik}
        {...otherProps}
        onChange={(evt) => formik.setFieldValue(
          item.name,
          formatter(evt.target.value),
        )}
        key={item.name}
        formItemClassName={item.disabled ? 'react-input-mask-item-disabled' : null}
      >
        {(inputProps) => <Input {...inputProps} />}
      </FormField>
    );
  }

  if (item.token) {
    const {
      pathParts, dependentFields, ...otherProps
    } = item;

    return (
      <FormField
        formik={formik}
        {...otherProps}
        onChange={(suggestion) => {
          formik.setFieldValue(
            item.name,
            getNestedObjectFieldValue(suggestion, pathParts),
          );
          if (dependentFields) {
            dependentFields.forEach(async (field) => {
              await formik.setFieldValue(
                field.name,
                getNestedObjectFieldValue(suggestion, field.pathParts),
              );
              await form.setFieldsValue({
                [field.name]: getNestedObjectFieldValue(
                  suggestion,
                  field.pathParts,
                ),
              });
              formik.setFieldTouched(field.name);
              // formik.validateField(field.name);
            });
          }
        }}
        defaultQuery={formik.values[item.name]}
        key={item.name}
      />
    );
  }

  if (item.selectParams) {
    const {
      dependentFields, selectParams, ...otherProps
    } = item;
    const {
      options, valueField, keyField, textField,
    } = selectParams;

    return (
      <FormField
        formik={formik}
        {...otherProps}
        onChange={item.onChange ? item.onChange : (value) => {
          formik.setFieldValue(item.name, value);
          if (dependentFields) {
            dependentFields.forEach(async (field) => {
              await formik.setFieldValue(
                field.name,
                getNestedObjectFieldValue(options.filter((element) => element.id === value)[0], field.pathParts),
              );
              await form.setFieldsValue({
                [field.name]: getNestedObjectFieldValue(
                  options.filter((element) => element.id === value)[0],
                  field.pathParts,
                ),
              });
              formik.setFieldTouched(field.name);
              // formik.validateField(field.name);
            });
          }
        }}
        key={item.name}
      >
        {options?.map((i) => (
          <Option value={i[valueField]} key={i[keyField]}>
            {i[textField]}
          </Option>
        ))}
      </FormField>
    );
  }

  if (item.checked) {
    const { checked, title, ...otherProps } = item;

    return (
      <FormField
        formik={formik}
        {...otherProps}
        checked={formik.values[checked]}
        key={item.name}
      >
        {title}
      </FormField>
    );
  }

  return <FormField formik={formik} {...item} />;
});

FormFieldSelector.propTypes = {
  formik: PropTypes.shape().isRequired,
  form: PropTypes.shape(),
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

FormFieldSelector.defaultProps = {
  form: null,
};

export default FormFieldSelector;
